










































































































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent } from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
// import accountModule from '@/store/modules/accountModule';
// import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
// import tasqsListModule from '@/store/modules/tasqsListModule';
// import metaDataModule from '@/store/modules/metaDataModule';
// import proceduresModule from '@/store/modules/proceduresModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import uniqid from 'uniqid';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    DateRangePicker,
    draggable,
  },
})
export default class FormAddFieldPopup extends Vue {
  @Prop({ type: Array, required: false }) allFields?: any;

   windowWidth = window.innerWidth;

   activeTableID: any = null;

   selectedInputType = 'Textbox'

   dropdownFieldLocation: any = []

   actionCategory = 'None';

   //  get categories() {
   //    return ['Construction', 'HSE', 'I&E', 'Maintenance', 'Production', 'Regulatory', 'None'].map((s) => ({ text: s, value: s }));
   //  }

   get availableFields() {
     return this.allFields.map((field) => ({ text: field.title, value: field.id }));
   }

   formField: any = {
     id: uniqid(),
     title: '',
     category: 'Textbox',
     type: 'Single',
     description: '',
     required: false,
     showLatestResponseOnWell: false,
     format: 'Standard',
     isCustomSource: true,
     sourceType: 'Custom',
     showDetails: true,
     useCurrentDate: true,
     hideField: false,
     localVal: '',
     source: '',
     showDescription: false,
     isMultiselect: false,
     options: [],
     visibilityRules: [],
   }

   nextStepHandler() {
     if (this.stepNo === 1) {
       this.stepNo = 2;
     } else {
       this.$emit('save-field', this.formField);
     }
   }

   errors: any = []

   dropdownFieldSelected: any = []

   get getStylesForNext() {
     if (this.stepNo === 2 && !this.formField.title.length) {
       return 'background: #b7b7b7; cursor: no-drop';
     }
     return 'background: #5be5c1; width: 175px;';
   }

   stepNo: any = 1;

   didSelectFieldsDropdown(e) {
     const rect: any = e.srcElement.parentElement.getBoundingClientRect();
     this.dropdownFieldLocation = [rect.x, rect.y + 50];
   }

   hideFieldsDropdown() {
     this.dropdownFieldLocation = [];
   }

   setMainCategory(name) {
     this.selectedInputType = name;
     this.formField.category = name;
     if (name === 'Date/Time') {
       this.formField.type = 'Date';
       this.formField.format = '12 Hours';
     } else if (name === 'Dropdown') {
       this.formField.type = 'dropdown';
       this.formField.sourceType = 'Custom';
     } else {
       this.formField.type = 'Single';
       this.formField.format = 'Standard';
     }
   }

     	async didFieldsSelectDropdownOption() {
     const findField = this.allFields.find((field) => field.id === this.dropdownFieldSelected[0].value);
     if (findField) {
       this.selectedInputType = findField.category;
       this.formField.category = findField.category;
       this.formField.type = findField.type;
       this.formField.description = findField.description;
       this.formField.required = findField.required;
       this.formField.showLatestResponseOnWell = findField.showLatestResponseOnWell;
       this.formField.format = findField.format;
       this.formField.isCustomSource = findField.isCustomSource;
       this.formField.sourceType = findField.sourceType;
       this.formField.showDetails = false;
       this.formField.useCurrentDate = findField.useCurrentDate;
       this.formField.isMultiselect = findField.isMultiselect;
       this.formField.options = findField.options;
       this.formField.visibilityRules = findField.visibilityRules;
     }
     this.dropdownFieldLocation = [];

     //  this.dropdownLocation = [];
   }
}

